import React, { useState, useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../layout/Layout';
import SEO from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import { Helmet } from 'react-helmet';



const Privacy = ({ data }) => {
	const [content, setContent] = useState("")
	const policyTextRef = useRef()
	const link = "https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/c4bf13c9-3e34-4792-8ca5-3debf35b55da-fr.json"
	const pageData = data?.allContentfulPrivacyPage?.edges[0]?.node;

	useEffect(() => {
		fetch(link)
			.then((response) => response.json())
			.then((data) => setContent(data.notices[Object.keys(data.notices)[0]]));
	}, [])

	if(!content){
		return <></>
	} 

	return (
		<Layout>
			<SEO
				lang="fr"
				title={pageData?.tItle || 'Publicis et Nous'}
				description={pageData?.description || 'Publicis et Nous'}
			/>
			<div id="main-wrapper" className="row">
				<Helmet>
					<script src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css" integrity="sha384-DztdAPBWPRXSA/3eYEEUWrWCy7G5KFbe8fFjk5JAIxUYHKkDx6Qin1DkWx51bBrb" crossorigin="anonymous"></script>
					<link rel="stylesheet" type="text/css" href="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css" class="otnotice-css"></link>
				</Helmet>
				<Nav />
				<section id="container" className="privacy col-lg-9 col-md-12">
					<div className="page-data-privacy inner-wrap">
						<div className="page-content inner-page">
							<div className="content-wrap">
								<div id="otnotice-cf03b220-3512-4bd1-9453-07b7a003b08e" className="otnotice mt-5">
									
									<div className="otnotice-content">
										<ul className="otnotice-menu">
												<li className="otnotice-menu-section"><a
																href="#otnotice-section-d51091f2-d876-4a64-acd5-9e8f935d1e8f">Introduction</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-cc500d40-b884-4805-ab0a-ad61207e7aa0">Privacy
																Policy</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-06c367d6-c4ae-44f1-b767-1b1f9d68dab9">I.
																Purposes of the processing of Personal Data</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-d22ca56f-26b3-442a-af5d-6ffe6961c4e1">II. What
																Personal Data do we collect and process?</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-94b70614-39ef-4b12-920a-d84859a083fa">III. What
																legal basis do we use?</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-e166a121-bb93-4fc0-ae9e-daa80793e068">IV. With
																whom do we share your Personal Data?</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-8bd9a325-5a4b-4978-98bb-fd69691e0765">V. Your
																rights regarding your Personal Data</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-2f043d2d-cd50-4e1f-b0f0-988cf73af457">VI.
																Retention periods</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-069be978-f524-4a38-b76b-d293c335b08a">VII. Data
																security</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-0c05eb29-73cf-49b4-8bc7-61b07cbe286b">VIII.
																Data transfer</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-c1f113a9-f24d-485f-8c11-5a9d0bdae412">IX.
																Additional information</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-0185cda7-df3c-4f59-8679-5b44dd2c4467">Cookie
																Notice</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-b6d2a51f-25fe-48c2-a946-668f8ff5a403">I. Social
																networks</a></li>
												<li className="otnotice-menu-section"><a href="#otnotice-section-ed37efa9-a714-43cf-9c62-27750645d616">II.
																Cookies</a></li>
										</ul>
										<div className="otnotice-menu-mobile">
                      <div className="otnotice-menu-selected-container"><span className="otnotice-menu-selected"
                              id="otnotice-menu-selected">Introduction</span><span
                              className="otnotice-menu-display otnotice-menu-display__expand"
                              id="otnotice-collapse-expand-icon"></span></div>
                      <ul className="otnotice-menu-mobile-container" style={{display: "none"}}>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-d51091f2-d876-4a64-acd5-9e8f935d1e8f">Introduction</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-cc500d40-b884-4805-ab0a-ad61207e7aa0">Privacy Policy</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-06c367d6-c4ae-44f1-b767-1b1f9d68dab9">I. Purposes of the processing of
                                  Personal Data</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-d22ca56f-26b3-442a-af5d-6ffe6961c4e1">II. What Personal Data do we
                                  collect and process?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-94b70614-39ef-4b12-920a-d84859a083fa">III. What legal basis do we
                                  use?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-e166a121-bb93-4fc0-ae9e-daa80793e068">IV. With whom do we share your
                                  Personal Data?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-8bd9a325-5a4b-4978-98bb-fd69691e0765">V. Your rights regarding your
                                  Personal Data</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-2f043d2d-cd50-4e1f-b0f0-988cf73af457">VI. Retention periods</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-069be978-f524-4a38-b76b-d293c335b08a">VII. Data security</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-0c05eb29-73cf-49b4-8bc7-61b07cbe286b">VIII. Data transfer</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-c1f113a9-f24d-485f-8c11-5a9d0bdae412">IX. Additional information</a>
                          </li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-0185cda7-df3c-4f59-8679-5b44dd2c4467">Cookie Notice</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-b6d2a51f-25fe-48c2-a946-668f8ff5a403">I. Social networks</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-ed37efa9-a714-43cf-9c62-27750645d616">II. Cookies</a></li>
                      </ul>
                    </div>
										<div  dangerouslySetInnerHTML={ {  __html: content?.content}}/>
										<div className="otnotice-version"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
	allContentfulPrivacyPage{
		edges {
			node{
				tItle
				description
			}
	}
	}
	}
`

export default Privacy;